var ready = function () {
  console.log("READUY");
  $("#dataTable").DataTable();
  $("#member_api_a_bank_name").select2();

  $("#check_recieved").click(function () {
    $("#admin__billing__resolve_check_payment_modal").modal();
  });

  $("#check_pay_now").click(function () {
    $("#billing_pay_now_modal").modal();
  });
};

$(document).ready(ready);
$(document).on("turbolinks:load", ready);
